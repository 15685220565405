<template>
  <el-date-picker
      :placeholder="`${$t('system.select_date')}...`"
      :size="size"
      @change="onChange"
      class="w-100"
      type="daterange"
      v-model="filter.value"
  />
</template>

<script>
import moment from 'moment';
import filtersGeneratorMixin from '../mixin/filtersGeneratorMixin';

export default {
  name: 'fieldDateRangePicker',
  mixins: [filtersGeneratorMixin],
  methods: {
    onChange(value) {
      this.filter.value = [moment(value[0]).format('YYYY-MM-DD'), moment(value[1]).format('YYYY-MM-DD')];
    },
  },
};
</script>

<style scoped>

</style>
